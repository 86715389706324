.work__filters {
  display: flex;
  align-items: center;
  column-gap: 1.875rem;
  margin-bottom: 2.5rem;
}
.work__item {
  cursor: pointer;
  font-weight: var(--font-bold);
  transition: 0.3s;
}
.work__item:hover {
  color: var(--first-color);
}
.work__container {
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875rem;
}
.work__card {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  justify-content: center;
  align-items: center;
}
.work__mask {
  background: #6c6ce5;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: 0.3s;
  opacity: 0;
}
.work__card:hover .work__mask {
  opacity: 0.9;
}
.work__category {
  color: #fff;
  background-color: var(--first-color);
  border-bottom-left-radius: 0.98rem;
  border-bottom-right-radius: 0.98rem;
  position: absolute;
  top: 0;
  left: 1.5rem;
  font-size: var(--small-font-size);
  display: inline-block;
  padding: 0.19rem 0.625rem;
  transform: translateY(-40px);
  transition: 0.3s;
}
.work__title {
  color: #fff;
  font-size: var(--h3-font-size);
  margin: 0 0 0 0.98rem;
  padding: 0 1.25rem;
  position: absolute;
  top: 3.75rem;
  transform: translateY(30px);
  transition: 0.3s;
  opacity: 0;
}
.work__button {
  color: #fff;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  font-size: var(--h3-font-size);
  display: block;
  background-color: #ffd15c;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  transition: 0.3s;
  opacity: 0;
}
.work__card:hover {
  opacity: 1;
}
.work__card:hover .work__title,
.work__card:hover .work__category,
.work__card:hover .work__button {
  opacity: 1;
  transform: translateY(0);
}
@media screen and (max-width: 1024px) {
  .work__filters {
    justify-content: center;
  }
  .work__container {
    grid-template-columns: repeat(2, 330px);
    justify-content: center;
    row-gap: 1.875rem;
  }
}
@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: 310px;
  }
  .work__filter {
    row-gap: 0.25rem;
  }
}
@media screen and (max-width: 350px) {
  .work__container {
    grid-template-columns: 1fr;
  }
}
