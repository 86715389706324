.home {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.intro {
  max-width: 540px;
  text-align: center;
}
.home__img {
  margin-bottom: 1.5rem;
  height: 160px;
  width: 160px;
}
.home__name {
  font-size: var(--h1-font-size);
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem;
}
.home__socials {
  display: flex;
  column-gap: 1.75rem;
  margin: 1.5rem;
}
.home__social-link {
  color: var(--title-color);
  font-size: 1.3rem;
  transition: 0.3s;
}
.home__social-link:hover {
  color: hsl(43, 100%, 68%);
}
.scroll__down {
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__scroll-name {
  font-size: var(--small-font-size);
}
.mouse {
  border: 2px solid #454360;
  display: block;
  width: 1.6rem;
  height: 2.5rem;
  margin: auto;
  margin-top: 0.75rem;
  border-radius: 1rem;
  position: relative;
}
@keyframes ani-mouse {
  0% {
    top: 29%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 29%;
  }
}
.wheel {
  background-color: var(--title-color);
  border-radius: 100%;
  width: 0.25rem;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 32%;
  transform: translateX(50%);
  animation: ani-mouse 2s linear infinite;
}
.shapes {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.shape {
  position: absolute;
}
.s1 {
  left: 2%;
  top: 10%;
}
.s2 {
  left: 18%;
  top: 10%;
}
.s3 {
  left: 5%;
  bottom: 30%;
}
.s4 {
  left: 2%;
  bottom: 10%;
}
.s5 {
  left: 44%;
  top: 10%;
}
.s6 {
  left: 36%;
  bottom: 10%;
}
.s7 {
  top: 20%;
  right: 125%;
}
.s8 {
  right: 24%;
  bottom: 20%;
}
.s9 {
  right: 2%;
  top: 10%;
}
.s10 {
  top: 45%;
  right: 11%;
}
.s11 {
  bottom: 10%;
  right: 2%;
}
@media screen and (max-width: 1024px) {
  .home__social-link {
    font-size: 1.125rem;
  }
}
