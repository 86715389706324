.main {
  justify-content: center;
  margin-left: 120px;
}

@media screen and (max-width: 1024px) {
  .main {
    margin-left: 0;
  }
}
